import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import notFoundStyles from "../styles/pages/404.module.css" 

const NotFoundPage = () => (
  <Layout location="404">
    <SEO title="404: Not Found" />
    <div className={ notFoundStyles.notFoundSection }>
      <div className={ notFoundStyles.box }></div>
      <div className={ notFoundStyles.text }>
        <h1>404</h1>
        <p>The page you are looking for does not exist.</p>
        <button onClick={() => {
            window.history.back()
        }}>Go Back</button>
        <div className={ notFoundStyles.box2 }></div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
